<template>
  <div class="email-verify">
    <div class="email-verify__container">
      <img src="@/assets/icons/check-circle-green.svg" alt="checked"/>
      <h1>
        Your email has been verified
      </h1>
      <p>
        You can now login with your account
      </p>
      <div class="btn">
        <router-link type="primary" class="link" to="/">
          Login
        </router-link>
        <div v-if="isVerified" class="loader">
          <the-loader></the-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from "../components/BaseComponents/TheLoader.vue";

export default {
  components: {
    TheLoader,
  },
  data() {
    return {
      isVerified: false,
    };
  },
  created() {
    this.emailVerification()
  },
  methods: {
    async emailVerification() {
      this.isVerified = true;

      try {
        const payload = {
          token: this.$route.query.token,
        };

        const response = await this.$store.dispatch(
            "auth/emailVerification",
            payload
        );

        if (response.status === 200) {
          this.isVerified = false;
        }
      } catch (err) {
        console.log(err);
      }

    },
  },
};
</script>

<style lang="scss">
.email-verify {
  padding: 8rem 4rem;
  height: 100vh;
  background-color: #eceef1;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    margin: 0 auto;
    text-align: center;
    width: 54rem;
    background-color: $color-white;
    padding: 5rem 2rem 6rem;

    h1 {
      font-family: $font-secondary-bold;
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 0;
    }

    p {
      font-family: $font-secondary;
      font-size: 1.6rem;
      text-align: center;
      opacity: 0.7;
      margin-bottom: 4rem;
    }

    img {
      width: 7rem;
      margin-bottom: 1rem;
    }

    .btn {
      position: relative;
      width: fit-content;
      margin: 0 auto;

      .link {
        font-family: $font-secondary-bold;
        font-size: 1.6rem;
        color: $color-white;
        padding: 1.3rem 12rem;
        background-color: $color-primary;
      }

      .loader {
        position: absolute;
        width: 100%;
        top: -1rem;
        background-color: rgba(255, 255, 255, 0.555);

        .ant-spin {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 4.5rem;

          .anticon-loading {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin: 0 1rem;

            .anticon-spin {
              width: 2rem;
              height: 2rem;
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}
</style>
